var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.modalId,"size":"lg","hide-footer":""},on:{"shown":_vm.load},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" 主たる営業担当者の変更 ")]},proxy:true}])},[_c('p',[_vm._v("営業担当者としての役割を引継ぐ方の情報をご入力ください。")]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.accept)}}},[_c('div',{staticClass:"p-3 password-bg"},[_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-offset-3"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.NAME'),"rules":{required:true, noSpecialChar: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.NAME_KANJI')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.NAME_KANJI_PLACEHOLDER')},model:{value:(_vm.newMainUser.name),callback:function ($$v) {_vm.$set(_vm.newMainUser, "name", $$v)},expression:"newMainUser.name"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.PHONENUMBER'),"rules":{required: true,phonenumber:true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.PHONENUMBER')}},[_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.PHONENUMBER_DESC')),expression:"$t('COMMON.PHONENUMBER_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.PHONENUMBER_PLACEHOLDER'),"maxlength":"20"},model:{value:(_vm.newMainUser.companyMobilePhone),callback:function ($$v) {_vm.$set(_vm.newMainUser, "companyMobilePhone", $$v)},expression:"newMainUser.companyMobilePhone"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.EMAIL_ADDRESS'),"rules":{required:true, email:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.EMAIL_ADDRESS')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.EMAIL_ADDRESS_PLACEHOLDER')},model:{value:(_vm.newMainUser.email),callback:function ($$v) {_vm.$set(_vm.newMainUser, "email", $$v)},expression:"newMainUser.email"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('USERS.ORGANIZATION_NAME'),"rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('USERS.ORGANIZATION_NAME')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('USERS.ORGANIZATION_NAME_PLACEHOLDER')},model:{value:(_vm.newMainUser.organizationName),callback:function ($$v) {_vm.$set(_vm.newMainUser, "organizationName", $$v)},expression:"newMainUser.organizationName"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.POSTAL_CODE'),"rules":"required|numericPostalNotHypen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.POSTAL_CODE')}},[_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.POSTAL_CODE_DESC')),expression:"$t('COMMON.POSTAL_CODE_DESC')",modifiers:{"focus":true,"top":true}}],staticClass:"d-inline-block",staticStyle:{"width":"180px"},attrs:{"type":"text","placeholder":_vm.$t('COMMON.POSTAL_CODE_PLACEHOLDER'),"maxlength":"8"},model:{value:(_vm.newMainUser.orgPostalCode),callback:function ($$v) {_vm.$set(_vm.newMainUser, "orgPostalCode", $$v)},expression:"newMainUser.orgPostalCode"}}),_c('j-button',{staticClass:"ml-2",attrs:{"buttonType":"button","variant":"light"},on:{"click":function($event){return _vm.onAutoAddress()}}},[_vm._v(_vm._s(_vm.$t('COMMON.AUTO_LOAD_ADDRESS')))])],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.ADDRESS_1'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.ADDRESS_1')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.ADDRESS_1_PLACEHOLDER')},model:{value:(_vm.newMainUser.orgAddress1),callback:function ($$v) {_vm.$set(_vm.newMainUser, "orgAddress1", $$v)},expression:"newMainUser.orgAddress1"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.ADDRESS_2'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.ADDRESS_2')}},[_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.ADDRESS_2_DESC')),expression:"$t('COMMON.ADDRESS_2_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.ADDRESS_2_PLACEHOLDER')},model:{value:(_vm.newMainUser.orgAddress2),callback:function ($$v) {_vm.$set(_vm.newMainUser, "orgAddress2", $$v)},expression:"newMainUser.orgAddress2"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])]),_c('div',{staticClass:"controls text-center mt-2"},[_c('j-button',{staticClass:"mt-3",attrs:{"variant":"primary","buttonType":"submit"}},[_vm._v(" "+_vm._s(_vm.isSelf?'保存':'送信')+" ")]),_c('j-button',{staticClass:"mt-3 ml-4",attrs:{"variant":"light","buttonType":"button"},on:{"click":function($event){return _vm.reject()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas','times']}}),_vm._v(" キャンセル ")],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }