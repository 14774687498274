<template>
  <b-modal :id="modalId" @shown="load" size="lg" hide-footer>
    <template v-slot:modal-title>
      主たる営業担当者の変更
    </template>
    <p>営業担当者としての役割を引継ぐ方の情報をご入力ください。</p>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent="handleSubmit(accept)">
        <div class="p-3 password-bg">
          <div class="mt-4" >
            <div class="row">
              <div class="col-6 col-offset-3">
                <validation-provider :name="$t('COMMON.NAME')" :rules="{required:true, noSpecialChar: true}"
                  v-slot="{ errors }">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.NAME_KANJI')">
                    <b-form-input type="text" :placeholder="$t('COMMON.NAME_KANJI_PLACEHOLDER')" v-model="newMainUser.name" />
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
                <validation-provider :name="$t('COMMON.PHONENUMBER')" :rules="{required: true,phonenumber:true }" v-slot="{ errors }">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.PHONENUMBER')">
                    <b-form-input 
                      type="text" 
                      :placeholder="$t('COMMON.PHONENUMBER_PLACEHOLDER')" 
                      v-b-popover.focus.top="$t('COMMON.PHONENUMBER_DESC')"
                      v-model="newMainUser.companyMobilePhone"
                      maxlength="20" />
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
                <validation-provider :name="$t('COMMON.EMAIL_ADDRESS')" :rules="{required:true, email:true}" v-slot="{ errors }">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.EMAIL_ADDRESS')">
                    <b-form-input type="text" :placeholder="$t('COMMON.EMAIL_ADDRESS_PLACEHOLDER')" v-model="newMainUser.email" />
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
                <!-- -->              
                <validation-provider :name="$t('USERS.ORGANIZATION_NAME')" :rules="{required:true}"
                  v-slot="{ errors }">
                  <b-form-group class="biz-input-group mb-0" :label="$t('USERS.ORGANIZATION_NAME')">
                    <b-form-input type="text" :placeholder="$t('USERS.ORGANIZATION_NAME_PLACEHOLDER')" v-model="newMainUser.organizationName" />
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
                <validation-provider :name="$t('COMMON.POSTAL_CODE')" rules="required|numericPostalNotHypen" v-slot="{ errors }">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.POSTAL_CODE')">
                    <b-form-input type="text" 
                      :placeholder="$t('COMMON.POSTAL_CODE_PLACEHOLDER')" 
                      class="d-inline-block" 
                      v-b-popover.focus.top="$t('COMMON.POSTAL_CODE_DESC')" 
                      maxlength="8"
                      style="width: 180px;" v-model="newMainUser.orgPostalCode" />
                    <j-button class="ml-2" buttonType="button" variant="light" @click="onAutoAddress()">{{$t('COMMON.AUTO_LOAD_ADDRESS')}}</j-button>
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
                <validation-provider :name="$t('COMMON.ADDRESS_1')" rules="required" v-slot="{ errors }">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.ADDRESS_1')">
                    <b-form-input type="text" :placeholder="$t('COMMON.ADDRESS_1_PLACEHOLDER')" v-model="newMainUser.orgAddress1"></b-form-input>
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
                <validation-provider :name="$t('COMMON.ADDRESS_2')" rules="required" v-slot="{ errors }">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.ADDRESS_2')">
                    <b-form-input 
                      type="text" 
                      :placeholder="$t('COMMON.ADDRESS_2_PLACEHOLDER')" 
                      v-b-popover.focus.top="$t('COMMON.ADDRESS_2_DESC')" 
                      v-model="newMainUser.orgAddress2"/>
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
        <div class="controls text-center mt-2">
          <j-button class="mt-3" variant="primary" buttonType="submit">
            {{isSelf?'保存':'送信'}}
          </j-button>
          <j-button class="mt-3 ml-4" variant="light" buttonType="button" @click="reject()">
            <font-awesome-icon :icon="['fas','times']"></font-awesome-icon>
            キャンセル
          </j-button>
        </div>
      </form>
    </ValidationObserver>
    
  </b-modal>
</template>

<script>
import AreaModel from '@/model/Area';

import UserInfo from '@/model/UserInfo';
import { setInteractionMode } from 'vee-validate'

export default {
  props: ['modalId','user', 'isSelf','oldMainUser'],
  data: function(){

    return {
      isGenerate: !this.isSelf,
      current: "",
      currentVisible: false,
      newMainUser: {}
    }
  },
  created: function(){
    setInteractionMode('passive');
  },
  methods:{
    accept(){
      this.$set(this.newMainUser, 'roles', this.oldMainUser.roles);
      if(this.oldMainUser.email === this.newMainUser.email){
        this.$warningMessage("現在の主たる営業担当者と異なるメールアドレスを設定してください。");return;
      }
      UserInfo.updateMainUser(this.$store.state.user.companyCode, {
        oldMain: this.oldMainUser,
        newMain: this.newMainUser,
      }).then(() => {
        this.$successMessage(this.$t('USERS.SUCCESS_MESSAGE'));
        this.$bvModal.hide(this.modalId);
        this.$router.go(this.$router.currentRoute)
      }).catch(({response}) => {
        if (response.data && response.data.errorCode === 'duplicate-emails') {
          const h = this.$createElement,
              msg = [
                h('div', {}, this.$t('COMMON.USER_DUPLICATE_WARNING')),
                h('p', response.data.emails[0])
              ]
          this.$errorMessage(msg, {noAutoHide: true})
        } else {
          this.$errorMessage(undefined)
        }
      });
    },
    reject(){
      this.$bvModal.hide(this.modalId);
    },
    load(){
      this.newMainUser = {}
      if(this.oldMainUser){
        this.$set(this.newMainUser, 'organizationName', this.oldMainUser.organizationName);
        this.$set(this.newMainUser, 'orgPostalCode', this.oldMainUser.orgPostalCode);
        this.$set(this.newMainUser, 'orgAddress1', this.oldMainUser.orgAddress1);
        this.$set(this.newMainUser, 'orgAddress2', this.oldMainUser.orgAddress2);
      }
    },
    onAutoAddress(){
      AreaModel.getAddress(this.newMainUser.orgPostalCode).then(result => {
        this.$set(this.newMainUser, 'orgAddressAreaCode', result.data.areaCode);
        this.$set(this.newMainUser, 'orgAddress1', result.data.address);
      }).catch(reason => console.error(reason));
    }
  }
  
}
</script>

<style lang="scss" scoped>
  .password-bg{
    background:$biz-side-bar-color;
  }
  .btn-light-link{
    border:none;
  }
</style>
