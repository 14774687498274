<template>
  <div>
    <div class="card biz-primary mt-4">
      <div class="card-header">
        {{$t('USERS.HEADER_MAIN_USER')}}
        <p>{{$t('USERS.HEADER_MAIN_USER_DESCRIPTION')}}</p>
      </div>
      <div class="card-body">
        <div class="biz-form-group seperated">
          <label class="biz-form-label required">
            {{$t('USERS.SALES_MANAGER')}}
            <font-awesome-icon 
              class="text-muted" 
              v-b-popover.hover="{ customClass: 'multi-line', content: $t('USERS.SALES_MANAGER_DESC'), html: true }" 
              icon="question-circle" />
          </label>
          <div class="biz-form-body">
            <div class="row">
              <div class="col">
                <validation-provider :name="$t('USERS.ORGANIZATION_NAME')" rules="required" v-slot="{ errors }">
                  <b-form-group class="biz-input-group mb-0" :label="$t('USERS.ORGANIZATION_NAME')">
                    <b-form-input type="text" :placeholder="$t('USERS.ORGANIZATION_NAME_PLACEHOLDER')" v-model="mMain.organizationName" />
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="col">
                <validation-provider :name="$t('COMMON.NAME')" rules="required|noSpecialChar" v-slot="{ errors }">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.NAME_KANJI')">
                    <b-form-input type="text" :placeholder="$t('COMMON.NAME_KANJI_PLACEHOLDER')" v-model="mMain.name" />
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="col">
                <validation-provider :name="$t('COMMON.PHONENUMBER')" :rules="{required: true,phonenumber:true  }" v-slot="{ errors }">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.PHONENUMBER')">
                    <b-form-input 
                      type="text" 
                      :placeholder="$t('COMMON.PHONENUMBER_PLACEHOLDER')" 
                      v-b-popover.focus.top="$t('COMMON.PHONENUMBER_DESC')" 
                      v-model="mMain.companyMobilePhone" />
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="col">
                <validation-provider :name="$t('COMMON.EMAIL_ADDRESS')" rules="required|email" v-slot="{ errors }">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.EMAIL_ADDRESS')">
                    <b-form-input type="text" :placeholder="$t('COMMON.EMAIL_ADDRESS_PLACEHOLDER')" v-model="mMain.email"
                      :disabled="true" />
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
                <b-link v-if="!isRegistrationWizard" v-on:click="openUpdateMainUserModal()">
                  {{$t('USERS.CHANGE_MAIN_USER')}}
                </b-link>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-form-group class="biz-input-group mt-3" :label="$t('USERS.ROLES')">
                  <b-form-checkbox class="d-inline-block mr-5" v-for="r in mMain.roles" v-bind:key="r.roleCode"
                    :value="true" :unchecked-value="false" v-model="r.checked" :disabled="true">
                    {{r.roleName}}
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="biz-form-group mt-3">
          <label class="biz-form-label required">
            {{$t('USERS.SALES_MANAGER')}}
            <font-awesome-icon 
              class="text-muted" 
              v-b-popover.hover="{ customClass: 'multi-line', content: $t('USERS.ORGANIZATION_ADDRESS_DESC'), html: true }" 
              icon="question-circle" /><br />
            {{$t('USERS.ORGANIZATION_ADDRESS')}}
          </label>
          <div class="biz-form-body">
            <div class="row">
              <div class="col">
                <validation-provider :name="$t('COMMON.POSTAL_CODE')" rules="required|numericPostalNotHypen" v-slot="{ errors }">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.POSTAL_CODE')">
                    <b-form-input type="text" 
                      :placeholder="$t('COMMON.POSTAL_CODE_PLACEHOLDER')" 
                      class="d-inline-block" 
                      v-b-popover.focus.top="$t('COMMON.POSTAL_CODE_DESC')" 
                      maxlength="8"
                      style="width: 180px;" v-model="mMain.orgPostalCode" />
                    <j-button class="ml-2" buttonType="button" variant="light" @click="onAutoAddress()">{{$t('COMMON.AUTO_LOAD_ADDRESS')}}</j-button>
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4">
                <validation-provider :name="$t('COMMON.ADDRESS_1')" rules="required" v-slot="{ errors }">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.ADDRESS_1')">
                    <b-form-input type="text" :placeholder="$t('COMMON.ADDRESS_1_PLACEHOLDER')" v-model="mMain.orgAddress1"></b-form-input>
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="col-4">
                <validation-provider :name="$t('COMMON.ADDRESS_2')" rules="required" v-slot="{ errors }">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.ADDRESS_2')">
                    <b-form-input 
                      type="text" 
                      :placeholder="$t('COMMON.ADDRESS_2_PLACEHOLDER')" 
                      v-b-popover.focus.top="$t('COMMON.ADDRESS_2_DESC')" 
                      v-model="mMain.orgAddress2"/>
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="card biz-primary mt-4">
      <div class="card-header">
        {{$t('USERS.HEADER_OTHER_USERS')}}
        <p>{{$t('USERS.HEADER_OTHER_USERS_DESC')}}</p>
      </div>
      <div class="card-body">
        <div class="biz-form-group seperated mt-3" v-for="(s, index) in mSubs" v-bind:key="mSubs[index].id">
          <label :class="'biz-form-label optional'">{{$t('USERS.OTHER_USER')}}{{index+1}}</label>
          <div class="biz-form-body">
            <div class="row">
              <div class="col">
                <validation-provider :name="$t('USERS.ORGANIZATION_NAME')" :rules="{required:false}"
                  v-slot="{ errors }" :vid="$t('USERS.ORGANIZATION_NAME') + '_sub' + index" mode="aggressive">
                  <b-form-group class="biz-input-group mb-0" :label="$t('USERS.ORGANIZATION_NAME')">
                    <b-form-input type="text" :placeholder="$t('USERS.ORGANIZATION_NAME_PLACEHOLDER')" v-model="mSubs[index].organizationName" />
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="col">
                <validation-provider :name="$t('COMMON.NAME')" :rules="{required:false, noSpecialChar: true}"
                  v-slot="{ errors }" :vid="$t('COMMON.NAME') + '_sub' + index" mode="aggressive">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.NAME_KANJI')">
                    <b-form-input type="text" :placeholder="$t('COMMON.NAME_KANJI_PLACEHOLDER')" v-model="mSubs[index].name" />
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="col">
                <validation-provider :name="$t('COMMON.PHONENUMBER')" :rules="{required: false,phonenumber:true }" 
                  v-slot="{ errors }" :vid="$t('COMMON.PHONENUMBER') + '_sub' + index" mode="aggressive">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.PHONENUMBER')">
                    <b-form-input 
                      type="text" 
                      :placeholder="$t('COMMON.PHONENUMBER_PLACEHOLDER')" 
                      v-b-popover.focus.top="$t('COMMON.PHONENUMBER_DESC')"
                      v-model="mSubs[index].companyMobilePhone"
                      maxlength="20" />
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="col">
                <validation-provider :name="$t('COMMON.EMAIL_ADDRESS')" :rules="{required:false, email:true}"
                  v-slot="{ errors }" :vid="$t('COMMON.EMAIL_ADDRESS') + '_sub' + index" mode="aggressive">
                  <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.EMAIL_ADDRESS')">
                    <b-form-input type="text" :placeholder="$t('COMMON.EMAIL_ADDRESS_PLACEHOLDER')" v-model="mSubs[index].email" />
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-form-group class="biz-input-group mt-3" :label="$t('USERS.ROLES')">
                  <b-form-checkbox class="d-inline-block mr-5" v-for="sr in mSubs[index].roles" v-bind:key="sr.roleCode"
                    :value="true" :unchecked-value="false" v-model="sr.checked" :disabled="sr.roleCode==='SB20'">
                    {{sr.roleName}}
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col text-right mb-2">
                <b-link style="cursor:pointer;" v-on:click="removeSub(mSubs[index], index)">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                  {{$t('USERS.OTHER_USER')}}{{index+1}}{{$t('COMMON.REMOVAL_OF')}}
                </b-link>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 text-right">
          <a href="javascript:void(0)" v-on:click="addSub()">
            <font-awesome-icon icon="plus"></font-awesome-icon>
            {{$t('USERS.OTHER_USER')}}{{$t('COMMON.ADD_OF')}}
          </a>
        </div>
      </div>
    </div>
    <update-main-user-modal 
      modal-id="update-main-user-modal" 
      :oldMainUser="mMain"
    ></update-main-user-modal>
  </div>
</template>

<script>
  import UserInfo from '@/model/UserInfo';
  import InitModel from '@/model/Init';
  import AreaModel from '@/model/Area';

  import UpdateMainUserModal from '@/components/register/UpdateMainUserModal.vue';

  export default {
    props: ["main", "subs", "isRegistrationWizard"],
    components: { UpdateMainUserModal },
    data(){
      return {
        mMain: {},
        mSubs: []
      }
    },
    watch:{
      main: function(val){
        this.mMain = val;
      },
      subs: function(val){
        this.mSubs = val;
        if(val && val.length <= 0){
          this.addSub();
        }
      },
      mMain: function(val){
        this.$emit('mainInput', val);
      },
      mSubs: function(val){
        this.$emit('subsInput', val);
      }
    },
    methods: {
      addSub() {
        InitModel.getRoles(this.$store.state.user.status.spotSupplier).then(result => {
          this.mSubs.push({ roles: result.data });
        });
      },
      removeSub(s, index) {
        if (s.code) {
          this.$bvModal.msgBoxConfirm(this.$t('USERS.DELETE_CONFIRM',{ number: index + 1}), {
              title: this.$t('USERS.DELETE_CONFIRM',{ number: index + 1}),
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: this.$t('COMMON.YES'),
              cancelTitle: this.$t('COMMON.NO'),
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            })
            .then(value => {
              if(value){
                UserInfo.deleteSub(this.$store.state.user.companyCode, s.code).then(() => {
                  if (index > -1) {
                    this.mSubs.splice(index, 1);
                  }
                }).catch(reason => console.error(reason));
              }  
            });
        } else {
          if (index > -1) {
            this.subs.splice(index, 1);
          }
        }
      },
      onAutoAddress(){
        AreaModel.getAddress(this.mMain.orgPostalCode).then(result => {
          this.$set(this.mMain, 'orgAddressAreaCode', result.data.areaCode);
          this.$set(this.mMain, 'orgAddress1', result.data.address);
        }).catch(reason => console.error(reason));
      },
      openUpdateMainUserModal(){
        this.$bvModal.show('update-main-user-modal');
      }
    }
  }
</script>

<style>

</style>