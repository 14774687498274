import Model from './_base';

export default class Area extends Model {
    static entity = 'area';
    
    constructor() {
        super(Area.entity);
    }
    
    static getAddress(zipCode){
      return Model.axios.get(this.url('/address?zipCode='+zipCode));
    }

    static getFullAreaTree(){
      return Model.axios.get(this.url(''));
    }
    
    static getAreaOptions(){
      return Model.axios.get(this.url('/options'));
    }
} 