var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card biz-primary mt-4"},[_c('div',{staticClass:"card-header"},[_vm._v(" "+_vm._s(_vm.$t('USERS.HEADER_MAIN_USER'))+" "),_c('p',[_vm._v(_vm._s(_vm.$t('USERS.HEADER_MAIN_USER_DESCRIPTION')))])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"biz-form-group seperated"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v(" "+_vm._s(_vm.$t('USERS.SALES_MANAGER'))+" "),_c('font-awesome-icon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({ customClass: 'multi-line', content: _vm.$t('USERS.SALES_MANAGER_DESC'), html: true }),expression:"{ customClass: 'multi-line', content: $t('USERS.SALES_MANAGER_DESC'), html: true }",modifiers:{"hover":true}}],staticClass:"text-muted",attrs:{"icon":"question-circle"}})],1),_c('div',{staticClass:"biz-form-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":_vm.$t('USERS.ORGANIZATION_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('USERS.ORGANIZATION_NAME')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('USERS.ORGANIZATION_NAME_PLACEHOLDER')},model:{value:(_vm.mMain.organizationName),callback:function ($$v) {_vm.$set(_vm.mMain, "organizationName", $$v)},expression:"mMain.organizationName"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.NAME'),"rules":"required|noSpecialChar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.NAME_KANJI')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.NAME_KANJI_PLACEHOLDER')},model:{value:(_vm.mMain.name),callback:function ($$v) {_vm.$set(_vm.mMain, "name", $$v)},expression:"mMain.name"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.PHONENUMBER'),"rules":{required: true,phonenumber:true  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.PHONENUMBER')}},[_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.PHONENUMBER_DESC')),expression:"$t('COMMON.PHONENUMBER_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.PHONENUMBER_PLACEHOLDER')},model:{value:(_vm.mMain.companyMobilePhone),callback:function ($$v) {_vm.$set(_vm.mMain, "companyMobilePhone", $$v)},expression:"mMain.companyMobilePhone"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.EMAIL_ADDRESS'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.EMAIL_ADDRESS')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.EMAIL_ADDRESS_PLACEHOLDER'),"disabled":true},model:{value:(_vm.mMain.email),callback:function ($$v) {_vm.$set(_vm.mMain, "email", $$v)},expression:"mMain.email"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])}),(!_vm.isRegistrationWizard)?_c('b-link',{on:{"click":function($event){return _vm.openUpdateMainUserModal()}}},[_vm._v(" "+_vm._s(_vm.$t('USERS.CHANGE_MAIN_USER'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{staticClass:"biz-input-group mt-3",attrs:{"label":_vm.$t('USERS.ROLES')}},_vm._l((_vm.mMain.roles),function(r){return _c('b-form-checkbox',{key:r.roleCode,staticClass:"d-inline-block mr-5",attrs:{"value":true,"unchecked-value":false,"disabled":true},model:{value:(r.checked),callback:function ($$v) {_vm.$set(r, "checked", $$v)},expression:"r.checked"}},[_vm._v(" "+_vm._s(r.roleName)+" ")])}),1)],1)])])]),_c('div',{staticClass:"biz-form-group mt-3"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v(" "+_vm._s(_vm.$t('USERS.SALES_MANAGER'))+" "),_c('font-awesome-icon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({ customClass: 'multi-line', content: _vm.$t('USERS.ORGANIZATION_ADDRESS_DESC'), html: true }),expression:"{ customClass: 'multi-line', content: $t('USERS.ORGANIZATION_ADDRESS_DESC'), html: true }",modifiers:{"hover":true}}],staticClass:"text-muted",attrs:{"icon":"question-circle"}}),_c('br'),_vm._v(" "+_vm._s(_vm.$t('USERS.ORGANIZATION_ADDRESS'))+" ")],1),_c('div',{staticClass:"biz-form-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.POSTAL_CODE'),"rules":"required|numericPostalNotHypen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.POSTAL_CODE')}},[_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.POSTAL_CODE_DESC')),expression:"$t('COMMON.POSTAL_CODE_DESC')",modifiers:{"focus":true,"top":true}}],staticClass:"d-inline-block",staticStyle:{"width":"180px"},attrs:{"type":"text","placeholder":_vm.$t('COMMON.POSTAL_CODE_PLACEHOLDER'),"maxlength":"8"},model:{value:(_vm.mMain.orgPostalCode),callback:function ($$v) {_vm.$set(_vm.mMain, "orgPostalCode", $$v)},expression:"mMain.orgPostalCode"}}),_c('j-button',{staticClass:"ml-2",attrs:{"buttonType":"button","variant":"light"},on:{"click":function($event){return _vm.onAutoAddress()}}},[_vm._v(_vm._s(_vm.$t('COMMON.AUTO_LOAD_ADDRESS')))])],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-4"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.ADDRESS_1'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.ADDRESS_1')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.ADDRESS_1_PLACEHOLDER')},model:{value:(_vm.mMain.orgAddress1),callback:function ($$v) {_vm.$set(_vm.mMain, "orgAddress1", $$v)},expression:"mMain.orgAddress1"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-4"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.ADDRESS_2'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.ADDRESS_2')}},[_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.ADDRESS_2_DESC')),expression:"$t('COMMON.ADDRESS_2_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.ADDRESS_2_PLACEHOLDER')},model:{value:(_vm.mMain.orgAddress2),callback:function ($$v) {_vm.$set(_vm.mMain, "orgAddress2", $$v)},expression:"mMain.orgAddress2"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])])])]),_c('div',{staticClass:"card biz-primary mt-4"},[_c('div',{staticClass:"card-header"},[_vm._v(" "+_vm._s(_vm.$t('USERS.HEADER_OTHER_USERS'))+" "),_c('p',[_vm._v(_vm._s(_vm.$t('USERS.HEADER_OTHER_USERS_DESC')))])]),_c('div',{staticClass:"card-body"},[_vm._l((_vm.mSubs),function(s,index){return _c('div',{key:_vm.mSubs[index].id,staticClass:"biz-form-group seperated mt-3"},[_c('label',{class:'biz-form-label optional'},[_vm._v(_vm._s(_vm.$t('USERS.OTHER_USER'))+_vm._s(index+1))]),_c('div',{staticClass:"biz-form-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":_vm.$t('USERS.ORGANIZATION_NAME'),"rules":{required:false},"vid":_vm.$t('USERS.ORGANIZATION_NAME') + '_sub' + index,"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('USERS.ORGANIZATION_NAME')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('USERS.ORGANIZATION_NAME_PLACEHOLDER')},model:{value:(_vm.mSubs[index].organizationName),callback:function ($$v) {_vm.$set(_vm.mSubs[index], "organizationName", $$v)},expression:"mSubs[index].organizationName"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.NAME'),"rules":{required:false, noSpecialChar: true},"vid":_vm.$t('COMMON.NAME') + '_sub' + index,"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.NAME_KANJI')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.NAME_KANJI_PLACEHOLDER')},model:{value:(_vm.mSubs[index].name),callback:function ($$v) {_vm.$set(_vm.mSubs[index], "name", $$v)},expression:"mSubs[index].name"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.PHONENUMBER'),"rules":{required: false,phonenumber:true },"vid":_vm.$t('COMMON.PHONENUMBER') + '_sub' + index,"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.PHONENUMBER')}},[_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.PHONENUMBER_DESC')),expression:"$t('COMMON.PHONENUMBER_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.PHONENUMBER_PLACEHOLDER'),"maxlength":"20"},model:{value:(_vm.mSubs[index].companyMobilePhone),callback:function ($$v) {_vm.$set(_vm.mSubs[index], "companyMobilePhone", $$v)},expression:"mSubs[index].companyMobilePhone"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.EMAIL_ADDRESS'),"rules":{required:false, email:true},"vid":_vm.$t('COMMON.EMAIL_ADDRESS') + '_sub' + index,"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.EMAIL_ADDRESS')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.EMAIL_ADDRESS_PLACEHOLDER')},model:{value:(_vm.mSubs[index].email),callback:function ($$v) {_vm.$set(_vm.mSubs[index], "email", $$v)},expression:"mSubs[index].email"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{staticClass:"biz-input-group mt-3",attrs:{"label":_vm.$t('USERS.ROLES')}},_vm._l((_vm.mSubs[index].roles),function(sr){return _c('b-form-checkbox',{key:sr.roleCode,staticClass:"d-inline-block mr-5",attrs:{"value":true,"unchecked-value":false,"disabled":sr.roleCode==='SB20'},model:{value:(sr.checked),callback:function ($$v) {_vm.$set(sr, "checked", $$v)},expression:"sr.checked"}},[_vm._v(" "+_vm._s(sr.roleName)+" ")])}),1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-right mb-2"},[_c('b-link',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.removeSub(_vm.mSubs[index], index)}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}}),_vm._v(" "+_vm._s(_vm.$t('USERS.OTHER_USER'))+_vm._s(index+1)+_vm._s(_vm.$t('COMMON.REMOVAL_OF'))+" ")],1)],1)])])])}),_c('div',{staticClass:"mt-4 text-right"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.addSub()}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.$t('USERS.OTHER_USER'))+_vm._s(_vm.$t('COMMON.ADD_OF'))+" ")],1)])],2)]),_c('update-main-user-modal',{attrs:{"modal-id":"update-main-user-modal","oldMainUser":_vm.mMain}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }